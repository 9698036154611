const AdminSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Profiles',
    name: 'sidebar.admin.profiles',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-user-line',
    children: [
      {
        title: 'Units',
        is_active: false,
        link: {
          name: 'admin.units'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Departments',
        is_active: false,
        link: {
          name: 'admin.departments'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-scan-2-line'
      },
      {
        title: 'Positions',
        is_active: false,
        link: {
          name: 'admin.positions'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-scan-line'
      },
      {
        title: 'Employees',
        is_active: false,
        link: {
          name: 'admin.employees'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      },
      {
        title: 'User Accounts',
        is_active: false,
        link: {
          name: 'admin.user-accounts'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      }
    ]
  },
  {
    title: 'Maintenances',
    name: 'sidebar.admin.maintenances',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Types',
        is_active: false,
        link: {
          name: 'admin.types'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-folder-3-line'
      },
      {
        title: 'Categories',
        is_active: false,
        link: {
          name: 'admin.categories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-folder-2-line'
      },
      {
        title: 'Statuses',
        is_active: false,
        link: {
          name: 'admin.statuses'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-signal-tower-line'
      },
      {
        title: 'Suppliers',
        is_active: false,
        link: {
          name: 'admin.suppliers'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-store-2-line'
      },
      {
        title: 'Brands',
        is_active: false,
        link: {
          name: 'admin.brands'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-store-3-line'
      },
      {
        title: 'Templates',
        is_active: false,
        link: {
          name: 'admin.templates'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-folders-line'
      }
    ]
  },
  {
    title: 'Statistics',
    name: 'sidebar.admin.statistics',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-chart-line',
    children: [
      {
        title: 'Total Costs',
        is_active: false,
        link: {
          name: 'admin.statistics.total-costs'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-exchange-dollar-line'
      },
      {
        title: 'Total Costs per Status',
        is_active: false,
        link: {
          name: 'admin.statistics.status-total-costs'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-exchange-funds-line'
      }
    ]
  },
  {
    title: 'Borrowing',
    name: 'sidebar.admin.borrowing',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-user-line',
    children: [
      {
        title: 'Borrowings',
        is_active: false,
        link: {
          name: 'admin.borrowings'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Borrowing Reservations',
        is_active: false,
        link: {
          name: 'admin.borrowing-reservations'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Histories',
        is_active: false,
        link: {
          name: 'admin.borrowing-histories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      }
    ]
  },
  {
    title: 'Inventories',
    is_heading: true,
    is_active: false,
    link: {
      name: 'sidebar.admin.inventories'
    },
    class_name: 'mt-2 font-weight-bolder'
  },
  {
    title: 'Items',
    is_active: false,
    link: {
      name: 'admin.inventories.items'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-settings-line'
  },
  {
    title: 'Peripherals',
    name: 'admin.inventories.peripherals',
    is_heading: false,
    is_active: true,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-settings-2-line',
    children: [
      {
        title: 'Item Details',
        is_active: false,
        link: {
          name: 'admin.inventories.peripherals.item-details'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-settings-2-line'
      },
      // {
      //   title: 'Swap',
      //   is_active: false,
      //   link: {
      //     name: 'admin.inventories.peripherals.swap'
      //   },
      //   class_name: '',
      //   is_icon_class: true,
      //   icon: 'ri-exchange-line'
      // },
      {
        title: 'Attach Or Detach',
        is_active: false,
        link: {
          name: 'admin.inventories.peripherals.attach-detach'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-stock-line'
      },
      {
        title: 'Assign Or Return',
        is_active: false,
        link: {
          name: 'admin.inventories.peripherals.assign-return'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-user-shared-line'
      }
    ]
  },
  {
    title: 'Upload Templated Items',
    is_active: false,
    link: {
      name: 'admin.inventories.upload-templated-items'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-settings-5-line'
  },
  {
    title: 'Inventory Histories',
    is_heading: true,
    is_active: false,
    link: {
      name: 'sidebar.admin.inventory-histories'
    },
    class_name: 'mt-2 font-weight-bolder'
  },
  {
    title: 'Items',
    is_active: false,
    link: {
      name: 'admin.inventory-histories.items'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-settings-line'
  },
  {
    title: 'Item Details',
    is_active: false,
    link: {
      name: 'admin.inventory-histories.peripherals'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-settings-2-line'
  }
]

export default AdminSideMenu
